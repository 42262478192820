import { useState, useEffect } from "react";
import { createModel } from "hox";
import { message } from "antd"
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { saleSoService } from "../../saleSoService"
import { formatNum } from "@/erp_subpackage/utils/util";
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile";
import { useLockFn } from "ahooks";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import moment from "moment";
import { IsFranchisees } from '@/utils/common'
import { bigNumber } from '../../../../../utils/util'

export const useSaleSoDetailModel = createModel(function () {
    const { user: { shopId, shopCode, shopName, userName, orgName, orgCode, bentityName, bentityCode } } = useGlobalModel()
    const [logisticsDraer, setLogisticsDraer] = useState(false);  //物流登记弹框
    const [settleVisible, setSettleVisible] = useState(false);   //结算弹框

    const [isAutoSave, setIsAutoSave] = useState(false)//自动保存

    const [changeSaleDrawer, serChangeSaleDrawer] = useState(false);  //转销售单弹框
    const [wsConfirmModal, setWsConfirmModal] = useState(false);    //库房确认弹框
    const [registerModal, setRegisterModal] = useState(false);     //入库登记弹框
    const [discountModal, setDiscountModal] = useState(false);     //折扣弹框
    const [checkModal, setCheckModal] = useState(false); //审核弹窗

    const [addModalVisible, setAddModalVisible] = useState(false)  //新增商品弹框
    const [vehicleEditAll, setVehicleEditAll] = useState([])//实时切换车辆 编辑
    const [vehicleAddAll, setVehicleAddAll] = useState([])//实时切换车辆 新增
    const [vinAddAll, setVinAddAll] = useState([]) // 实时切换vin  新增
    const [vinEditAll, setVinEditAll] = useState([])  // 实时切换vin编辑
    const initDesc = {
        numAll: 0,  //数量
        itemNumAll: 0, //项数
        totalAll: 0,//总计
        discountAll: 0,//折扣,
        receivableAll: 0 //应收
    }
    const initForm = {
        shopName,
        createUser: userName,
        bentityName: bentityName,
        favorableAmount: 0,
        salesTypeName: "正常销售",
        salesTypeCode: DictCodeEnmu.SALE_TYPE_NORMAL,
        priceTypeName: "零售价",
        priceTypeCode: DictCodeEnmu.PRICE_SELECTION_RETAIL,
        orderSourceCode: DictCodeEnmu.SOURCE_ADD,
        orderSourceName: "新增",
    }
    //新增详情form
    const [detailAddForm, setDetailAddForm] = useState(initForm)
    //新增详情列表
    const [detailAddList, setDetailAddList] = useState([])
    //新增详情列表选中行
    const [detaiAddDataRowKeys, setDetaiAddDataRowKeys] = useState([]);
    const [detaiAddDataRow, setDetaiAddDataRow] = useState([]);
    const [addDesc, setAddDesc] = useState(initDesc)

    //查看数据
    //查看详情form
    const [detailLookForm, setDetailLookForm] = useState({})
    //查看详情列表
    const [detailLookList, setDetailLookList] = useState([])
    const [lookDesc, setLookDesc] = useState(initDesc)
    //查看结算详情
    const [lookSettleDetails, setLookSettleDetails] = useState([])
    //详情数据
    //详情列表参数
    const [detailDataSource, setDetailDataSource] = useState([]);
    //详情表格选中行
    const [detailDataRowKeys, setDetailDataRowKeys] = useState([]);
    const [detailDataRow, setDetailDataRow] = useState([]);
    const [detailEditForm, setDetailEditForm] = useState({})
    const [editDesc, setEditDesc] = useState(initDesc)
    //编辑结算详情
    const [editSettleDetails, setEditSettleDetails] = useState([])


    //已选商品列表
    const [addDataSource, setAddDataSource] = useState([]);
    //表单改变或表格数据发生改变时，控制功能按钮的启用禁用
    const [topButsControl, setTopButsControl] = useState(false)

    const [saveBtnLoading, setSaveBtnLoading] = useState(false); //  保存按钮

    //实时详情列表货位数据
    const [shelfAllList, setShelfAllList] = useState([]);
    useEffect(() => {
        setDetailAddForm({ ...initForm, shopId, shopName });
    }, [shopId])
    //设置底部总数展示（查看|编辑|查看）
    useEffect(() => {
        let newDesc = { ...initDesc };
        detailDataSource && detailDataSource.forEach(item => {
            // newDesc.numAll += Number(item.num);
            // newDesc.totalAll += Number(item.totalAmount);
            // newDesc.discountAll += Number(item.totalAmount) - Number(item.discountedAmount) + Number(item.decreaseAmount)
            // newDesc.receivableAll += Number(item.receivableAmount)

            newDesc.numAll = +bigNumber.add(Number(item.num), Number(newDesc.numAll))
            newDesc.totalAll = +bigNumber.add(Number(item.totalAmount), Number(newDesc.totalAll))
            newDesc.discountAll = +bigNumber.add(bigNumber.add(+bigNumber.minus(Number(item.totalAmount), Number(item.discountedAmount)), Number(item.decreaseAmount)), Number(newDesc.discountAll))
            newDesc.receivableAll = +bigNumber.add(Number(item.receivableAmount), Number(newDesc.receivableAll))
        });
        if ("favorableAmount" in detailEditForm) {
            // newDesc.receivableAll = newDesc.receivableAll - Number(detailEditForm.favorableAmount);
            // newDesc.discountAll = newDesc.discountAll + Number(detailEditForm.favorableAmount)
            newDesc.receivableAll = +bigNumber.minus(Number(newDesc.receivableAll), Number(detailEditForm.favorableAmount))
            newDesc.discountAll = +bigNumber.add(Number(newDesc.discountAll), Number(detailEditForm.favorableAmount))
        }
        newDesc.itemNumAll = detailDataSource && detailDataSource.length
        setEditDesc(newDesc);
    }, [detailDataSource, detailEditForm])

    useEffect(() => {
        let newDesc = { ...initDesc };
        detailAddList && detailAddList.forEach(item => {
            // newDesc.numAll += Number(item.num);
            // newDesc.totalAll += Number(item.totalAmount);
            // newDesc.discountAll += Number(item.totalAmount) - Number(item.discountedAmount) + Number(item.decreaseAmount)
            // newDesc.receivableAll += Number(item.receivableAmount)

            newDesc.numAll = +bigNumber.add(Number(item.num), Number(newDesc.numAll))
            newDesc.totalAll = +bigNumber.add(Number(item.totalAmount), Number(newDesc.totalAll))
            newDesc.discountAll = +bigNumber.add(bigNumber.add(+bigNumber.minus(Number(item.totalAmount), Number(item.discountedAmount)), Number(item.decreaseAmount)), Number(newDesc.discountAll))
            newDesc.receivableAll = +bigNumber.add(Number(item.receivableAmount), Number(newDesc.receivableAll))
        });
        if ("favorableAmount" in detailAddForm) {
            // newDesc.receivableAll = newDesc.receivableAll - Number(detailAddForm.favorableAmount);
            // newDesc.discountAll = newDesc.discountAll + Number(detailAddForm.favorableAmount)

            newDesc.receivableAll = +bigNumber.minus(Number(newDesc.receivableAll), Number(detailAddForm.favorableAmount))
            newDesc.discountAll = +bigNumber.add(Number(newDesc.discountAll), Number(detailAddForm.favorableAmount))
        }
        newDesc.itemNumAll = detailAddList && detailAddList.length
        setAddDesc(newDesc);
    }, [detailAddList, detailAddForm])

    useEffect(() => {
        let newDesc = { ...initDesc };
        detailLookList && detailLookList.forEach(item => {
            // newDesc.numAll += Number(item.num);
            // newDesc.totalAll += Number(item.totalAmount);
            // newDesc.discountAll += Number(item.totalAmount) - Number(item.discountedAmount) + Number(item.decreaseAmount)
            // newDesc.receivableAll += Number(item.receivableAmount)

            newDesc.numAll = +bigNumber.add(Number(item.num), Number(newDesc.numAll))
            newDesc.totalAll = +bigNumber.add(Number(item.totalAmount), Number(newDesc.totalAll))
            newDesc.discountAll = +bigNumber.add(bigNumber.add(+bigNumber.minus(Number(item.totalAmount), Number(item.discountedAmount)), Number(item.decreaseAmount)), Number(newDesc.discountAll))
            newDesc.receivableAll = +bigNumber.add(Number(item.receivableAmount), Number(newDesc.receivableAll))
        });
        if ("favorableAmount" in detailLookForm) {
            // newDesc.receivableAll = newDesc.receivableAll - Number(detailLookForm.favorableAmount);
            // newDesc.discountAll = newDesc.discountAll + Number(detailLookForm.favorableAmount)


            newDesc.receivableAll = +bigNumber.minus(Number(newDesc.receivableAll), Number(detailLookForm.favorableAmount))
            newDesc.discountAll = +bigNumber.add(Number(newDesc.discountAll), Number(detailLookForm.favorableAmount))
        }
        newDesc.itemNumAll = detailLookList && detailLookList.length
        setLookDesc(newDesc);
    }, [detailLookList, detailLookForm])

    //新增表单缓存
    const updateAddFormVal = (val) => {
        let newVal = val
        setDetailAddForm({ ...detailAddForm, ...newVal })
    }
    //编辑表单缓存
    const updatEditFormVal = (val) => {
        let newVal = val
        setDetailEditForm({ ...detailEditForm, ...newVal })
    }
    //获取一条编辑或查看数据
    const getDetailEditOne = async (id, mode) => {
        let { retData } = await saleSoService.one(id);
        const { details, settleDetails, saleTime, ...from } = retData
        const newDetails = details?.map((item) => {
            return {
                ...item,
                totalAmount: +formatNum(Number(item.num) * Number(item.price)),
                termNum: Number(item.num) + Number(item.inventoryNum), //判断编辑数量的条件
                detailsId: item.id,
                receivableAmount: (item.discountedAmount - Number(item.decreaseAmount)) ?? 0
            }
        })
        let newFrom = {
            ...from,
            saleTime: saleTime ? moment(saleTime) : null,
            // paymentMethodCode:settleDetails.length === 0 ? '' : settleDetails[0].payMethodCode
        }
        if (retData && mode === "edit") {
            setDetailDataSource(newDetails ?? [])
            setDetailEditForm(newFrom)
            setTopButsControl(false)
            setEditSettleDetails(settleDetails ?? [])
        } else if (retData && mode === "look") {
            setDetailLookForm(newFrom);
            setDetailLookList(newDetails ?? [])
            setLookSettleDetails(settleDetails ?? [])
        }
        return retData
    }

    //添加到详情列表
    const transformsToPoDetailList = (mode, selectedList = addDataSource) => {
        let detailList = mode === "edit" ? detailDataSource : detailAddList;
        const repetition = [];
        const newDetailData = []
        selectedList.forEach(Item => {
            let isSelect = detailList && detailList.find(item => item.purchaseId === Item.id);
            let { id, materialCode, materialName, inventoryNum, availableStock, num, price, amount, discountRate, ...params } = Item
            price = +(price ?? 0);
            discountRate = +(discountRate ?? 100);
            if (!isSelect) {
                newDetailData.push({
                    ...Item,
                    price,
                    totalAmount: +formatNum(num * price),
                    discountedAmount: +formatNum((num * price * discountRate) / 100),
                    receivableAmount: +formatNum((num * price * discountRate) / 100),   // 小计 默认等于折扣金额
                    // 单项优惠金额
                    decreaseAmount: 0,
                    inventoryNum: availableStock,
                    discountRate,
                    stockId: Item.id,
                    detailsId: id,
                    id: "",
                    purchaseId: id,
                });
            } else {
                repetition.push(Item.materialName);
            }
        });
        (mode === "edit" ? setDetailDataSource : setDetailAddList)([...detailList, ...newDetailData]);
        repetition.length != 0 && message.warning(`商品名称：【${repetition.join(",")}】，采购单号相同，重复的将不在添加。`);
    }


    //删除详情列表数据
    const removeDetailList = async (mode, Material) => {
        if (mode === "add") {
            const filterDetailList = Material ? detailAddList.filter(el => !!!(Material.detailsId === el.detailsId)) : detailAddList.filter(el => !!!detaiAddDataRowKeys.find(ele => ele === el.detailsId));
            // const filterDetailList = detailAddList.filter(el => !!!detaiAddDataRowKeys.find(ele => ele === el.materialCode))
            setDetailAddList(filterDetailList)
            message.success("删除成功")
            setDetaiAddDataRowKeys([]);
            setDetaiAddDataRow([]);
        } else if (mode === "edit") {
            const backendDetailList = Material ? Material.id ? [{ id: Material.id }] : [] : detailDataRow.reduce((all, next) => {  //前端页面维护的需要删除的id
                if (next.id) {
                    all.push({ id: next.id });
                }
                return all;
            }, []);

            //删除详情列表调用的方法
            const deleteData = () => {
                const filterDetailList = Material ? detailDataSource.filter(el => !!!(Material.detailsId === el.detailsId)) : detailDataSource.filter(el => !!!detailDataRowKeys.find(ele => ele === el.detailsId));
                setDetailDataSource(filterDetailList)
                message.success("删除成功")
                setDetailDataRowKeys([]);
                setDetailDataRow([]);
                setIsAutoSave(true)
            }

            if (backendDetailList.length > 0) {
                await saleSoService.materialDelete({ ids: backendDetailList, saleCode: detailEditForm.saleCode }).then(res => {
                    if (res.retData) {
                        deleteData()
                    } else {
                        message.error("删除失败")
                    }
                })
            } else {
                deleteData()
            }
        }
    }
    //新增采购单
    const insertDetail = useLockFn(async (params) => {
        //判断每个商品的销售价格不能低于采购价
        let filterGoods = detailAddList.filter(item => +item.price < +(item.latestPurPrice > 0 ? item.latestPurPrice : item.purchasePrice))
        if (filterGoods.length > 0) {
            message.warning(`商品【${filterGoods.map(item => item.materialName).join()}】的价格低于采购价，请修改后保存`)
            return false
        }
        setSaveBtnLoading(true)
        let { retData } = await saleSoService.insert({
            ...params,
            details: detailAddList,
            createUser: userName,
            shopId,
            shopName,
            shopCode,
            // bentityName,
            // bentityCode,
            orgName,
            orgCode,
        });
        setSaveBtnLoading(false)
        return retData;

    })
    //编辑采购单
    const editDetail = useLockFn(async (params) => {
        //判断每个商品的销售价格不能低于采购价
        let filterGoods = detailDataSource.filter(item => +item.price < +(item.latestPurPrice > 0 ? item.latestPurPrice : item.purchasePrice))
        if (filterGoods.length > 0) {
            message.warning(`商品【${filterGoods.map(item => item.materialName).join()}】的价格低于采购价，请修改后保存`)
            return false
        }
        setSaveBtnLoading(true)
        let { retData } = await saleSoService.update({
            ...params,
            details: detailDataSource,
            updateUser: userName,
            shopId,
            shopName,
            shopCode,
            // bentityName,
            // bentityCode,
            orgName,
            orgCode,
        });
        setSaveBtnLoading(false)
        return retData;
    })


    const downloadTpl = useLockFn(async () => {
        let result = await saleSoService.downloadTpl();
        convertRes2Blob(result)
    })

    const warehousing = useLockFn(async () => {
        let { id, saleCode } = detailEditForm
        let params = {
            id,
            saleCode,
            // storekeeper: user.username
            storekeeper: userName
        }
        let { retData } = await saleSoService.warehousing(params);
        return retData
    })

    const onSettle = useLockFn(async (params) => {
        // let { retData } = await saleSoService.settle(params);
        let { retData } = !IsFranchisees() ? await saleSoService.settle(params) : await saleSoService.settleJoin(params);
        return retData
    })

    // 商城审核
    const onExamine = useLockFn(async (params) => {
        let { retData } = await saleSoService.examine(params);
        return retData
    })

    const supplementInsert = useLockFn(async (params) => {
        let { retData } = await saleSoService.supplementInsert({
            ...params,
            details: detailAddList,
            createUser: userName,
            shopId,
            shopName,
            shopCode,
            bentityName,
            bentityCode,
            orgName,
            orgCode,
        });
        return retData;
    })

    return {
        settleVisible,
        changeSaleDrawer,
        wsConfirmModal,
        registerModal,
        logisticsDraer,
        discountModal,
        checkModal,
        vinEditAll,
        vinAddAll,
        setSettleVisible,
        serChangeSaleDrawer,
        setWsConfirmModal,
        setRegisterModal,
        setLogisticsDraer,
        setDiscountModal,
        vehicleEditAll, setVehicleEditAll,
        vehicleAddAll, setVehicleAddAll,
        // data 
        addModalVisible,
        detailAddForm,
        detailAddList,
        detaiAddDataRow,
        addDesc,
        detailLookForm,
        detailLookList,
        lookDesc,
        detailDataSource,
        detailDataRowKeys,
        detailDataRow,
        detailEditForm,
        editDesc,
        addDataSource, setAddDataSource,
        shelfAllList,
        detaiAddDataRowKeys,
        // model
        setVinEditAll,
        setVinAddAll,
        setCheckModal,
        setAddModalVisible,
        setDetailAddForm,
        setDetailAddList,
        setDetaiAddDataRow,
        setAddDesc,
        setDetailLookForm,
        setDetailLookList,
        setLookDesc,
        setDetailDataSource,
        setDetailDataRowKeys,
        setDetailDataRow,
        setDetailEditForm,
        setEditDesc,
        updateAddFormVal,
        updatEditFormVal,
        getDetailEditOne,
        transformsToPoDetailList,
        removeDetailList,
        insertDetail,
        editDetail,
        setShelfAllList,
        setDetaiAddDataRowKeys,
        downloadTpl,
        warehousing,
        onSettle,
        initForm,
        topButsControl, setTopButsControl,
        supplementInsert,
        lookSettleDetails, setLookSettleDetails,
        editSettleDetails, setEditSettleDetails,
        onExamine,
        isAutoSave, setIsAutoSave, saveBtnLoading, setSaveBtnLoading
    }
});