import React, { useState, useEffect } from 'react'
import { Modal, Button, Input, message, Select } from 'antd';
import moment from 'moment';
import "./settleScaniaPrint.css";
import skny from "../../../assets/images/skny.png";
import ouman from "../../../assets/images/ouman.png";
import zf from "../../../assets/images/zf.png";
import iveco from "../../../assets/images/iveco.png";
import yhjt from "../../../assets/images/yhjt.png";
import ztyhjt from "../../../assets/images/ztyhjt.png";
import { globalPrompt } from '../message';
import { useDictModel } from '../../pages/base/dict/dictModel';
import useGlobalModel, { getFindItem } from '../../model/globalModel';
import { momentFormat } from '../../utils/util';
import { IS_TEST } from '@/config/app';

let shopObject = {
    "SHOP-M-00000017": {
        "img": skny,
        "address": "新疆乌鲁木齐市米东区金汇西路2877号",
        "phone": "0991-5310666     18016861905",
        "Bank": "招商银行乌鲁木齐分行解放北路支行",
        "BankAccount": "8530 8058 0110 001",
        "Fax": "0991-5310666     18016861905"
    },
    "SHOP-M-00000018": {
        "img": ouman,
        "phone": "0991-5310666     13209963230",
        "Bank": "新疆天山农村银行股份有限公司米东区支行",
        "BankAccount": "8070 1001 2010 1140 42179",
        "Fax": "0991-5310666     13209963230"

    },
    "SHOP-M-00000020": {
        "img": skny,
        "address": "天津津南经济开发区 旺港路15号",
        "phone": "022-28577398",
        "Bank": "中国工商银行股份有限公司天津金地支行",
        "BankAccount": "0302061609100023871",
        "Fax": "022-28577392"
    },
    "SHOP-M-00000019": {
        "img": skny,
        "address": "辽宁省沈阳市于洪区旺港街201号13门",
        "phone": "18600830027",
        "Bank": "中国工商银行股份有限公司沈阳于洪支行",
        "BankAccount": "3301009209200071238",
        "Fax": ""
    },
    "SHOP-M-00000016": {
        "img": skny,
        "address": "石家庄高新区太行大街与南三环交叉口南行500米路东第1栋1-3层",
        "phone": "0311-88036200",
        "Bank": "中国工商银行股份有限公司栾城支行",
        "BankAccount": "0402020709300237638",
        "Fax": ""
    },
    "SHOP-M-00000021": {
        "img": skny,
        "address": "北京市大兴区长子营镇长通路4号",
        "phone": "010-81280170",
        "Bank": "工行北京沙河支行",
        "BankAccount": "0200 0117 0900 6770 729",
        "Fax": "010-81280170"
    }
};
//打印logo设置
let IIMgEmum ={
    SKNY : "skny",
    OUMAN : "ouman",
    ZF : "zf",
    IVECO : "iveco"
}
let logoOptions = [ //新路王
    { label: "斯堪尼亚logo", value: IIMgEmum.SKNY },
    { label: "依维柯logo", value: IIMgEmum.IVECO },
    { label: "曼恩logo", value: IIMgEmum.OUMAN },
    { label: "采埃孚logo", value: IIMgEmum.ZF }
]
export const SaleScaniaPrint = ({
    title = "",
    visible,
    onClose,
    columns,
    itemClumns,
    itemDataSource,
    dataSource,
    formVal,
    isTechnician = false,
    footerVisible= true,
}) => {
    const { getTreeNodeName, getTreeNodeOne } = useDictModel();
    const { CustomerData } = useGlobalModel()
    const [settlementPartyName, setSettlementPartyName] = useState("")
    // 遍历商品td
    const mapGoods = (goods) => {
        return columns.filter(item => item).map((item, index) => <td key={index}>{item.render ? item.render(goods[item.dataIndex]) : goods[item.dataIndex]}</td>)
    }
    //遍历项目td
    const itemGoods = (goods) => {
        return itemClumns?.filter(item => item).map((item, index) => <td key={index}>{item.render ? item.render(goods[item.dataIndex]) : goods[item.dataIndex]}</td>)
    }
    const [imgVal, setImgVal] = useState(skny)

    const print = () => {
        const el= document.getElementById('settle-scania-print-container');
        const iframe= document.createElement('IFRAME');
        let doc = null;
        document.body.appendChild(iframe);
        doc = iframe.contentWindow.document;
        // 引入打印的专有CSS样式，根据实际修改
        doc.write(`<LINK rel="stylesheet" type="text/css" href="./settleScaniaPrint.css">`);
        doc.write(el?.innerHTML);
        doc.close();
        // 获取iframe的焦点，从iframe开始打印
        iframe.contentWindow.focus();
        iframe.onload = () => {
            iframe.contentWindow.print();
            document.body.removeChild(iframe);
        };
        if (navigator.userAgent.indexOf("MSIE") > 0) {
            document.body.removeChild(iframe);
        }
    }
    const setLogo = () => {
        let logoVal= 'skny'
        globalPrompt("modal", {
            width: 380,
            title: "设置logo",
            type: "info",
            // text: "",
            text: <>
                <span>请选择Logo类型</span>
                <Select
                    showSearch={true}
                    defaultValue={ 'skny'}
                    options={logoOptions}
                    onChange={(value, option) => logoVal = value}
                />
            </>,
            okText: "确定",
            onOk: () => {
                return new Promise((resolve, reject) => {
                    resolve(logoVal)
                }).then(res => {
                    switch (logoVal) {
                        case 'skny':
                            return setImgVal(skny)
                        case "ouman":
                            return setImgVal(ouman)
                        case "zf":
                            return setImgVal(zf)
                        case "iveco":
                            return setImgVal(iveco)
                        default:
                            return setImgVal(skny)
                    }
                    // setImgVal(logoVal)
                })
            },
            cancelText: "取消",
            onCancel: () => {
            }
        })
    }
    return <Modal
        title="打印详情"
        width={800}
        visible={visible}
        onCancel={onClose}
        footer={footerVisible?[
            <Button type="primary" onClick={onClose} key="back"> 取消</Button>,
            <Button type="primary" onClick={print} key="submit1">确认打印</Button>,
            <Button type="primary" onClick={setLogo} key="logo">设置Logo</Button>,

        ]:[]}
    >
        <div id="settle-scania-print-container" className="print-box">
            <div className="settle-container">
                <div className="settle-header">
                    <div className="h2">
                        <h2>{`${title}销售结算单`}</h2>
                    </div>
                    <div className={`logo `}>
                        <img src={IS_TEST ? ztyhjt : yhjt} alt="" />
                        {<div>欧豪富港</div>}
                    </div>
                    <div className={`skny`}>
                        <img src={imgVal} alt="斯堪尼亚" />
                    </div>
                </div>
                <table>
                    <tbody>
                        <tr>
                            <td>服务厂:</td>
                            <td colSpan={3}>{title}</td>
                            {/* <td>工单号:</td>
                            <td>{formVal.maintenanceCode}</td> */}
                            <td>结算时间:</td>
                            <td>{formVal.settleTime}</td>
                        </tr>
                        {/* <tr>
                            <td colSpan={6}></td>
                        </tr> */}
                        <tr>
                            <td colSpan={6}></td>
                        </tr>
                        <tr>
                            <td colSpan={6}></td>
                        </tr>
                        <tr>
                            <td>销售单号:</td>
                            <td>{formVal.maintenanceCode}</td>
                            <td>制单时间:</td>
                            <td>{formVal.enterTime}</td>
                            <td>销售时间:</td>
                            <td>{momentFormat(formVal.saleTime)}</td>
                            {/* <td>托修单位:</td>
                            <td>{formVal.customerName}</td>
                            <td>车辆类型:</td>
                            <td>{formVal.vehicleTypeName}</td>
                            <td>出厂时间:</td>
                            <td>{formVal.deliveryTime}</td> */}
                        </tr>
                        <tr>
                            <td>客户名称:</td>
                            {/* <td colSpan={3}>{formVal.customerName}</td> */}
                            <td colSpan={3}>{formVal.customerName}</td>
                            {/* <td>{formVal.contact}</td>
                            <td>车牌号:</td>
                            <td>{formVal.licensePlate}</td> */}
                            <td>行驶里程:</td>
                            <td>{formVal.mileage}<span className="span-right">公里/每小时</span></td>
                        </tr>
                        <tr>
                            <td>联系人:</td>
                            <td>{formVal.contact}</td>
                            <td>联系电话:</td>
                            <td>{formVal.phone}</td>
                            <td>客户地址:</td>
                            <td style={{ width: 220 }}>{formVal.customerAddress}</td>
                            {/* <td>联系电话:</td>
                            <td>{formVal.phone}</td>
                            <td>底盘号:</td>
                            <td>{formVal.vin}</td>
                            <td>结算日期:</td>
                            <td>{formVal.settleTime}</td> */}
                        </tr>
                        <tr>
                            <td>车辆类型:</td>
                            <td>{formVal.vehicleTypeName}</td>
                            <td>车牌号:</td>
                            <td>{formVal.licensePlate}</td>
                            <td>底盘号:</td>
                            <td>{formVal.vin}</td>
                        </tr>
                        <tr>
                            {/* <td>维修类型:</td>
                            <td>{formVal.serviceNatureName}</td> */}
                            <td>结算方式:</td>
                            <td>{getTreeNodeName(formVal.payType)}</td>
                            <td>结算方:</td>
                            {/* <td>{settlementPartyName}</td> */}
                            <td style={{ width: 150 }}>{formVal.settlementPartyName}</td>
                        </tr>
                    </tbody>
                </table>
                <table className="ming-xi">
                    <tbody>
                        <tr>
                            {/* <td>序号</td>
                            <td>项目编码</td>
                            <td>修理项目</td>
                            <td>工时</td>
                            <td style={{ textAlign: "right" }}>单价</td>
                            <td style={{ textAlign: "right" }}>折扣</td>
                            
                            <td style={{ textAlign: "right" }}>工时金额</td> */}
                            {itemClumns && itemDataSource && itemDataSource.length > 0 && itemClumns.filter(item => item).map((item, index) =>
                                <td key={index}>{item.title}</td>
                            )}
                        </tr>
                        {itemDataSource && itemDataSource.length > 0 &&
                            itemDataSource.map((item, index) =>
                                <tr key={index}>
                                    {itemGoods(item)}
                                </tr>
                            )}
                        <tr className="part">
                            {/* <td>序号</td>
                            <td>零件编码</td>
                            <td>零件名称</td>
                            <td>数量</td>
                            <td style={{ textAlign: "right" }}>单价</td>
                            <td style={{ textAlign: "right" }}>折扣</td>
                            <td style={{ textAlign: "right" }}>零件金额</td> */}
                            {columns && dataSource && dataSource.length > 0 && columns.filter(item => item).map((item, index) =>
                                <td key={index}>{item.title}</td>
                            )}
                        </tr>
                        {dataSource && dataSource.length > 0 &&
                            dataSource.map((item, index) =>
                                <tr key={index}>
                                    {mapGoods(item)}
                                </tr>
                            )
                        }
                        <tr style={{ height: "20px" }}>
                            <td colSpan={7}></td>
                        </tr>
                    </tbody>
                </table>
                <div className="total-price">
                    <table>
                        <tbody>
                            <tr>
                                <td>零件小计</td>
                                <td style={{ textAlign: "right" }} colSpan={2}>{formVal.materialScienceAmount}</td>
                            </tr>
                            <tr>
                                <td>优惠金额</td>
                                <td style={{ textAlign: "right" }} colSpan={2}>{formVal.discountTotalAmount}</td>
                            </tr>
                            <tr>
                                <td>费用合计</td>
                                <td style={{ textAlign: "right" }} colSpan={2}>{formVal.totalAmount}</td>
                            </tr>
                            <tr>
                                <td>{formVal.payType === "settle-type.cash" ? '实收' : '应收'}人民币金额(RMB)&nbsp;&nbsp;{formVal.paymentMethodName}</td>
                                <td style={{ textAlign: "right" }} colSpan={2}>{formVal.receivableTotalAmount}</td>
                            </tr>
                            <tr>
                                <td>大写人民币</td>
                                <td style={{ textAlign: "right" }} colSpan={2}>{formVal.receivableTotalAmountCN}</td>
                            </tr>
                        </tbody>
                    </table>
                    {/* <table>
                        <tbody>
                            <tr style={{ width: "90%" }}>
                                <td style={{ margin: "20px 0 0 -250px" }}>{formVal.payType === "settle-type.cash" ? '实收' : '应收'}人民币金额(RMB)<span>{formVal.customerName}{formVal.paymentMethodName}</span></td>
                                <td style={{ textAlign: "right" }} colSpan={3}>{formVal.receivableTotalAmount}</td>
                            </tr>                            
                        </tbody>
                    </table> */}
                </div>
                {/* <div className="paybox">
                    <div className="paybox-item">结算类型:<span>&nbsp;&nbsp;{getTreeNodeName(formVal.payType as string)}</span></div>
                    <div className="paybox-item">{formVal.payType === "settle-type.cash" ? '实收:' : '应收:'}<span>&nbsp;&nbsp;{formVal.receivableTotalAmount}</span></div>
                </div> */}
                <p className="remarks" style={{ margin: "10px 0 20px" }}>备注：{formVal.note}</p>
                <div className="print-flex">
                    <div>制单<span>&nbsp;&nbsp;{formVal.createUser}</span></div>
                    <div>库房<span>&nbsp;&nbsp;</span></div>
                    <div>质检<span>&nbsp;&nbsp;{formVal.formVal}</span></div>
                    <div>财务<span>&nbsp;&nbsp;{formVal.formVal}</span></div>
                </div>
                <div className={`sign-open`}>
                    <div>托修人签字:</div>
                    <div>维修厂签字:</div>
                </div>
                <div className={`warm-reminder`}>
                    <p>预防性更换温馨提示：</p>
                    <p>前轮轴承80万公里，后轮轴承60万公里，离合器分离轴承70万公里</p>
                </div>
                <div className="footer">
                    <span>{title}</span>
                    <span>地址：<span>天津津南经济开发区 旺港路15号</span></span>
                    <span>电话：<span>18016861905</span></span>
                    <span>传真：<span>0991-5310666</span></span>
                </div>
                <p style={{ "marginTop": "20px", textAlign: "right" }}>打印日期:&nbsp;&nbsp;&nbsp;{moment(new Date()).format('YYYY-MM-DD HH:mm')}</p>
            </div>
        </div>
    </Modal>
}