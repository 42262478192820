import styled from '@emotion/styled'
import axios from 'axios'
import React, { useState, useEffect, useRef } from 'react'
import { fetchEventSource } from '@microsoft/fetch-event-source'
import ReactMarkdown from 'react-markdown'
import { Avatar, Button, message } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { API_SERVER_HOST } from '@/config/app'
import { getUserInfo } from '@/utils/dataStorage'
import { getToken } from '@/utils/dataStorage'
import { IS_TEST } from '../../config/app'

const UserType = {
    user: 'user',
    bot: 'bot',
}

// type 1 用户 2 机器人
const initialMessages = [
    {
        type: UserType.bot,
        data: '您好，我有什么可以帮助您的吗?',
    },
]

function Chat({ inputs, difyInfo, app_code }) {
    //消息储存
    const [messages, setMessages] = useState(initialMessages)
    const messagesRef = useRef(null)
    //用户新输入的信息
    const [newMessage, setNewMessage] = useState('智能分析')
    // 聊天窗口
    const chatWindowRef = useRef(null)

    // 发送新消息的处理函数
    const sendMessage = () => {
        if (messagesRef.current.findIndex((item) => item.loading) > -1) {
            return message.warning('接口请求中,请接口完成后再试！')
        }
        let query = newMessage.trim()
        if (query) {
            let id = `${new Date().getTime()}${Math.floor(Math.random() * 10000000)} `
            //添加一条用户信息，再加一个机器人信息
            let newMessage = [...messagesRef.current, { type: UserType.user, data: query, id }, { type: UserType.bot, data: '', id, loading: true }]
            setMessages(newMessage)
            fetch(query, id)
            setNewMessage('') // 清空输入框
        }
    }

    //消息变化 储存到ref中，并吧窗口滚动到底部
    useEffect(() => {
        scrollToBottom()
        messagesRef.current = messages
    }, [messages])

    // 滚动到底部的函数
    const scrollToBottom = () => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight
        }
    }

    useEffect(() => {
        sendMessage()
    }, [])

    const fetch = async (query, id) => {
        // 拼接的数据
        let messageStr = ''
        let jsonStr = ''
        //是否是隐藏内容 如果```包裹，用于判断
        let isHind = false

        fetchEventSource(`${API_SERVER_HOST}/yh-ai-server/chat/backend/chat-messages`, {
            method: 'POST',
            headers: {
                // Authorization: getDifyAuthorization(),
                Authorization: getToken(),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                inputs,
                query,
                response_mode: 'streaming',
                conversation_id: getConversationId(),
                app_code,
                // user: getUserInfo()?.nickname,
            }),
            openWhenHidden: true,
            onopen(response) {
                //判断接口有没有正常返回
                if (response.status !== 200) {
                    messageStr = `接口${response.status}`
                }
            },
            onmessage(event) {
                let d
                if (event && event.data) {
                    d = JSON.parse(event?.data)
                    console.log(d)
                    if (d.event === 'message') {
                        // 根据```判断是消息还是json数据
                        if (d.answer.includes('```')) {
                            //json标识
                            isHind = !isHind
                            let arr = d.answer.split('```')
                            messageStr += arr[0] || ''
                            addMessages({ data: messageStr, conversation_id: d.conversation_id, loading: true }, id)
                            jsonStr += arr[1] || ''
                            return
                        }
                        if (!isHind) {
                            messageStr += d.answer
                            addMessages({ data: messageStr, conversation_id: d.conversation_id, loading: true }, id)
                        } else {
                            jsonStr += d.answer
                        }
                    }
                }
            },
            onerror(error) {
                message.error('Error:', error)
            },
            onclose() {
                let json = null
                try {
                    if (jsonStr) {
                        json = JSON.parse(jsonStr.replace('json', ''))
                    }
                } catch (err) {
                    message.error('json格式化失败')
                }
                console.log(messageStr)
                console.log(jsonStr)
                addMessages({ data: messageStr, answerJson: json, loading: false }, id)
            },
        })
    }

    const addMessages = (data, id) => {
        data = { ...data, type: UserType.bot, id }
        //根据id判断那条信息
        const index = messagesRef.current.findIndex((item) => item.id === id && item.type === UserType.bot)
        messagesRef.current[index] = { ...messagesRef.current[index], ...data }
        setMessages([...messagesRef.current])
    }

    //获取ConversationId
    const getConversationId = () => {
        for (let i = messagesRef.current.length - 1; i >= 0; i--) {
            let item = messagesRef.current[i]
            const { conversation_id, type } = item
            if (type === UserType.bot && conversation_id) {
                return conversation_id
            }
        }
        return ''
    }

    return (
        <Box>
            <div className="App">
                <p className="title">{IS_TEST ? '众腾' : '云豪'}智能聊天机器人</p>
                <div ref={chatWindowRef} className="chat-window">
                    {messages.map((msg) => (
                        <div className="message-box">
                            <div className={`avatar-box`}>
                                {msg.type === UserType.user && <Avatar size={24} icon={<UserOutlined />} />}
                                {msg.type === UserType.bot && <img src="https://file-server.yunhaochefu.com/image/6cca15048a86475949dde44f5428e977" />}
                            </div>
                            <div className="message-info">
                                {msg.type === UserType.user && <div className="user-info">{msg.data}</div>}
                                {msg.type === UserType.bot && (
                                    <div className="bot-info">
                                        <ReactMarkdown>{msg.data}</ReactMarkdown>
                                        {msg.loading && <text class="loading-icon"></text>}
                                        {msg.answerJson && (
                                            <button
                                                className="choose"
                                                onClick={() => {
                                                    difyInfo(msg.answerJson)
                                                }}
                                            >
                                                智能选择
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="input-container">
                    <input
                        type="text"
                        placeholder=""
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyPress={(e) => (e.key === 'Enter' ? sendMessage() : null)} // 监听回车键发送消息
                    />
                    <button onClick={sendMessage}>发送</button> {/* 发送按钮 */}
                </div>
            </div>
        </Box>
    )
}

const Box = styled.div`
    width: 100%;
    height: 100%;
    .App {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        background: linear-gradient(180deg, #f0edf7 0.03%, #ebeaf5 32.19%, #e8e8f3 68.86%, #e4eaf7 99.12%);
        padding: 10px;
        box-sizing: border-box;
        border-radius: 10px;
    }

    .title {
        font-size: 14px;
        line-height: 1;
        margin-bottom: 10px !important;
    }

    .chat-window {
        width: 100%;
        // height: 300px;
        flex: 1;
        border: 1px solid #dedede;
        border-radius: 10px;
        background: linear-gradient(180deg, #f5f4f6, #e6ebf7);
        overflow-y: auto;
        padding: 10px;
        box-sizing: border-box;
    }

    .message-box {
        width: 100%;
        overflow: hidden;
        margin-bottom: 10px;
        display: flex;
        align-items: start;
    }

    .avatar-box {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: linear-gradient(90deg, #8c8ef7, #2e6ee7);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        img {
            width: 70%;
            height: 70%;
        }
    }

    .message-info {
        flex: 1;
        margin-left: 10px;
        font-size: 14px;
    }

    .user-info {
        padding: 5px 10px;
        border-radius: 5px;
        background-color: #e7f8ff;
        display: inline-block;
    }
    .bot-info {
        padding: 5px 10px;
        border-radius: 5px;
        background-color: #fff;
        display: inline-block;
    }

    .choose {
        padding: 0 20px;
        border: none;
        border-radius: 5px;
        background: linear-gradient(90deg, #2e6ee7, #8c8ef7);
        color: #fff;
        cursor: pointer;
        margin: 10px 0;
    }

    .loading-icon {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 2px solid rgba(0, 0, 0, 0.1); /* 外边框颜色，透明度可以根据需要调整 */
        border-top-color: #333; /* 顶部边框颜色，即加载图标的“活动”部分 */
        animation: spin 1s linear infinite; /* 旋转动画 */
    }
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .input-container {
        display: flex;
        width: 100%;
        margin-top: 10px;
    }

    .input-container input {
        flex: 1;
        padding: 10px;
        border: 1px solid #dedede;
        border-radius: 10px;
        box-sizing: border-box;
        height: 40px;
    }

    .input-container button {
        padding: 5px 10px;
        margin-left: 10px;
        border: none;
        border-radius: 10px;
        background: linear-gradient(90deg, #2e6ee7, #8c8ef7);
        color: #fff;
        cursor: pointer;
    }

    .input-container button:hover {
        background-color: #218838;
    }

    pre,
    code {
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    p {
        margin-bottom: 0 !important;
    }
`

export default Chat
